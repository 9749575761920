import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';
import ContentBlockImageRight from "../components/ContentBlockImageRight";
import ContentBlockImageLeft from "../components/ContentBlockImageLeft";
import pwb1 from '../assets/images/pwb1.jpg';
import pwb2 from '../assets/images/pwb2.jpg';
import pwb3 from '../assets/images/pwb3.jpg';
import Partners from "../components/Partners";

const IndexPage = () => (
  <Layout>
    <Header />
    <header className="masthead bg-light">
      <div className="container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-black-50 text-uppercase">{config.heading}</h1>
          <h2 className="text-black-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              About
            </a>
          </Scroll>
        </div>
          {/*<iframe id="nCaptcha" src="http://localhost:1234/" width="600px" height="200px" allow-same-origin></iframe>*/}
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-black mb-4">Build tech for “everyone-inclusive” Web3 economy</h2>
            <h4 className="text-black mb-4">
              What we do:
            </h4>
            <p className="text-black">
              We help Web1 and Web2 companies to find and concrete their spot at Web3 economy space
            </p>
          </div>
        </div>
      </div>
    </section>

    {/*<section className="projects-section bg-light">*/}
    {/*  <div className="container">*/}
    {/*    <ContentBlockNone {...{title1:'Technology', title2:'Education'}}/>*/}
    {/*  </div>*/}
    {/*</section>*/}

    <section id="projects" className="projects-section">
      <div className="container">
        <h2 className="fixed-sh text-black-50 mb-4 text-center">TECHBRIDGE EVOLUTION</h2>
        <ContentBlockImageRight {...{title:'Web1', image:pwb1, text:'Tomsk Center of Distant Education'}}/>
        <ContentBlockImageLeft {...{title:'Web2.0', image:pwb2, text:`
         Prepsportswear<br/>
         Greenscroll<br/>
         WordPress<br/>
        `}}/>
        <ContentBlockImageRight {...{title:'Web3', image:pwb3, text:` Blockgeeks<br/> Login with NEAR<br/><a href="https://learnnear.club/">Learn NEAR Club</a><br/>
        Your Project - Your name`}}/>
      </div>
    </section>

    {/*<Partners />*/}

    <Subscribe />

    <SocialLinks />

    <Footer />
  </Layout>
);

export default IndexPage;
