import React from "react";

export default function ContentBlockImageRight({title, image, text}) {
  return (
    <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
      <div className="col-lg-6">
        <img className="img-fluid" src={image} alt="" />
      </div>
      <div className="col-lg-6">
        <div className="text-center h-100 project">
          <div className="d-flex h-100">
            <div className="project-text w-100 my-auto text-center text-lg-left">
              <h2 className="text-black">{title}</h2>
              <p className="mb-0 text-black" dangerouslySetInnerHTML = {{__html:text}}>
              </p>
              <hr className="d-none d-lg-block mb-0 ml-0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
