import React from 'react';
import ContactForm from './ContactForm';

export default function Subscribe() {
  return (
    <section id="signup" className="signup-section bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <i className="far fa-paper-plane fa-2x mb-2 text-black"></i>
            <h2 className="text-black mb-5">Contacts</h2>
            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
    </section>
  );
}
