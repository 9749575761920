import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const validateEmail = (value) => {
    let error;
    if (!value) {
        error = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = 'Invalid email';
    }
    return error;
};

const validateField = (value) => {
    let error;
    if (!value) {
        error = 'This field is required';
    }
    return error;
};

const initialValues = {
    firstName: '',
    email: '',
    message: ''
};
const styles = {
    container: {
        display: 'block',
        flexDirection: 'column',
        alignItems: 'center',
    },
    label: {
        display: 'block',
        marginBottom: 8
    },
    input: {
        fontSize: 16,
        padding: 8,
        marginBottom: 16,
        width: '100%',
        maxWidth: 500
    },
    inputHover: {
        border: 'none'
    },
    error: {
        color: 'red',
        fontSize: 16,
        marginBottom: 8
    },
    submit: {
        backgroundColor: 'orange',
        color: 'white',
        padding: '12px 32px',
        borderRadius: 4,
        border: 'none',
        cursor: 'pointer',
        alignSelf: 'center',
        marginTop: 16
    },
    textarea: {
        fontSize: 16,
        padding: 8,
        marginBottom: 16,
        width: '100%',
        maxWidth: 500,
        height: 120
    },
    nCaptchaContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 16,
    },
};
const ContactForm = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(false);

        try {
            let message = 'Techbridge Contact: ';
            message += Object.entries(values)
                .map(([fieldName, fieldValue]) => `${fieldName}: ${fieldValue}`)
                .join('; ');

            const response = await fetch('https://tb-messanger.herokuapp.com/send-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message }),
            });

            if (response.ok) {
                console.log('Message sent successfully');
                setIsSubmitted(true);
                setIsFormValid(true);
            } else {
                console.error('Error sending message');
                setIsFormValid(false);
            }
        } catch (error) {
            console.error('Error sending message', error);
            setIsFormValid(false);
        }
    };

    const validateForm = (values) => {
        let errors = {};

        setIsFormValid(window.nCaptchaWallet.nCaptcha.isValid);

        if (!window.nCaptchaWallet.nCaptcha.isValid) {
            errors['form'] = 'Please verify the form with nCaptcha before submitting';
        }

        return errors;
    };

    if (isSubmitted) {
        return (
            <div style={styles.container}>
                <h2>Successfully sent!</h2>
            </div>
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={validateForm}
        >
            {({ isSubmitting, errors }) => (
                <Form style={styles.container}>
                    {!isFormValid && errors.form && (
                        <div style={styles.error}>{errors.form}</div>
                    )}
                    <div style={styles.nCaptchaContainer}>
                        <div id="nCaptcha-verification" data-account="techbridge.near" data-price="1"></div>
                    </div>
                    <div>
                        <label htmlFor="firstName" style={styles.label}>
                            Your name
                        </label>
                        <Field
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Your name"
                            validate={validateField}
                            style={styles.input}
                        />
                        <ErrorMessage name="firstName" component="div" style={styles.error} />
                    </div>
                    <div>
                        <label htmlFor="email" style={styles.label}>
                            Your email
                        </label>
                        <Field
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Your email"
                            validate={validateEmail}
                            style={styles.input}
                        />
                        <ErrorMessage name="email" component="div" style={styles.error} />
                    </div>
                    <div>
                        <label
                            htmlFor="message" style={styles.label}>
                            How can we help you?
                        </label>
                        <Field
                            as="textarea"
                            type="text"
                            name="message"
                            id="message"
                            placeholder="How can we help you?"
                            validate={validateField}
                            style={styles.textarea}
                        />
                        <ErrorMessage name="message" component="div" style={styles.error} />
                    </div>
                    <button type="submit" disabled={isSubmitting} style={styles.submit}>
                        Submit
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default ContactForm;

